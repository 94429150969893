import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import CatbearsFeedbackBtn from 'components/CatbearsFeedbackBtn/CatbearsFeedbackBtn';
import "./LogoCatbears.scss"
import FeedbackPopup from 'components/Popups/FeedbackPopup/FeedbackPopup';
import { GlobalContext } from "context/GlobalContext";
import { useHistory } from 'react-router-dom';

function LogoCatbears({isSoundSwitch, isFeedbackBtn, isHovering, hideLogoOnMobile, isCentered, isTapToLandingPage}) {

  const {ShowFeedback, setShowFeedback} = useContext(GlobalContext);
  const history = useHistory();


  function closeFeedbackPopup () {
    setShowFeedback(false)
  }

  const goHome = () => {
    isTapToLandingPage && history.push('/');
  }

  function onClick (){
    if(isTapToLandingPage) window.location.href = 'https://app.catbears.com';
  }
  const {i18n } = useTranslation();

  return (
    <>
      {ShowFeedback && 
        <FeedbackPopup 
          closeFeedbackPopup={closeFeedbackPopup}
          feedbackSource={"theatre"}
        />
      }
    <div className={"LogoCatbears-container noselect " + (hideLogoOnMobile && "hideLogoOnMobile") + " " + (isCentered && "LogoCatbears-container__centered")}>
      <div className="toggle-switch hovering">
        {isSoundSwitch && <ToggleSwitch isSFX /> }
        {isFeedbackBtn && <CatbearsFeedbackBtn />}
      </div>
        <img onClick={goHome} className={"catbears-logo " + (isHovering && "hovering")} src={(i18n.translator.language == `en` ? "/assets/icons/logo-catbears__en.png" : "/assets/icons/logo-catbears__he.png")}/>
    </div>
    </>
  );
}

export default LogoCatbears;
