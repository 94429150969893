import React, {useState, useEffect} from "react";
import { io } from "socket.io-client";
import "./PWall.scss";
import { useTranslation } from "react-i18next";
import Xbutton from "components/Xbutton";
import { useHistory } from 'react-router-dom';
import PlanToggleWithPayPalButtons from "./PlanToggleWithPayPalButtons";

function PWall_Main({onExit, setSubscriptionID }) {
  
    const { t } = useTranslation();
    const history = useHistory();

    const [PlanSelected, setPlanSelected] = useState("monthly")

    const gotoLogin = () => {
      history.push('/login');
    }

    return (
      <>
        <div className="PWall_container">
            <div className="PWall_container__nav">
              <Xbutton 
                sizeM
                onClose={onExit}
              />
              <div className="PWall_login-line">
                <p>{t("loginFlow.alreadyAccount")}</p>
                <div className="PWall_login-line_link" onClick={gotoLogin}>{t("loginFlow.logIn")}</div>
              </div>
            </div>
            <div className="PWall_content">
              <div className="PWall_container__right">
                <div className="content">
                  <div className="PWall_-title">{t(`paywall-catbears.main-title`)}</div>
                  <div className="PWall_-description">{t(`paywall-catbears.main-description`)}</div>
                  <div className="">
                    <ul className="no-bullet">
                      <li className="PWall_li">
                        <span className="check-bullet"></span>
                        <p className="">{t(`paywall-catbears.bullet_01`)}</p>
                      </li>
                      <li className="PWall_li">
                        <span className="check-bullet"></span>
                        <p className="">{t(`paywall-catbears.bullet_02`)}</p>
                      </li>
                      <li className="PWall_li">
                        <span className="check-bullet"></span>
                        <p className="">{t(`paywall-catbears.bullet_03`)}</p>
                      </li>
                      <li className="PWall_li">
                        <span className="check-bullet"></span>
                        <p className="">{t(`paywall-catbears.bullet_04`)}</p>
                      </li>
                      <li className="PWall_li">
                        <span className="check-bullet"></span>
                        <p className="">{t(`paywall-catbears.bullet_05`)}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="PWall_footer">
                  <PlanToggleWithPayPalButtons setSubscriptionID={setSubscriptionID}/>
                </div>
              </div>
              {/* <div className="PWall_container__left">
                <img src="/assets/course/theatre/course-thumbnail_theatre_03.jpg" alt="catbears theatre" />
              </div> */}
            </div>
          </div>
      </>
    )
  }
  
  export default PWall_Main