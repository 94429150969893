import React, {useState, useEffect} from "react";
import { io } from "socket.io-client";
import "./PWall.scss";
import { useTranslation } from "react-i18next";
import Xbutton from "components/Xbutton";
import { useHistory } from 'react-router-dom';
import PlanToggleWithPayPalButtons from "./PlanToggleWithPayPalButtons";

function PWall_Success({onExit }) {
  
    const { t } = useTranslation();
    const history = useHistory();

    const [PlanSelected, setPlanSelected] = useState("monthly")

    const gotoLogin = () => {
      history.push('/login');
    }
    
      // const finishOnboarding = () => {
      //   updateUserIsSubscribedMutation({
      //     variables: {
      //       isOnboardingComplete: true
      //     }
      //   })
      
      //   setIsOnboardingON(false)
    
      // }

    return (
      <>
        <div className="PWall_container">
            <div className="PWall_container__nav">
              <Xbutton 
                sizeM
                onClose={onExit}
              />
              <div className="PWall_login-line">
                <p>Connecting with PayPal</p>
              </div>
            </div>
            <div className="PWall_content">
              <div className="PWall_container__right">
                <div className="content">
                   ✅ Payment receivedפ
                </div>
              </div>
            </div>
          </div>
      </>
    )
  }
  
  export default PWall_Success