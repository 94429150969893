import React, { createContext, useState } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [VolumeSwitchState, setVolumeSwitchState] = useState(true);
  const [ShowFeedback, setShowFeedback] = useState(false);
  const [volumeBar, setVolumeBar] = useState(100); // New state for volume control
  const [isPWallOpen, setIsPWallOpen] = useState(false);

  return (
    <GlobalContext.Provider value={{ 
      VolumeSwitchState, 
      setVolumeSwitchState, 
      ShowFeedback,
      setShowFeedback,
      volumeBar, 
      setVolumeBar,
      isPWallOpen,
      setIsPWallOpen
    }}>
      <>
        {children}
      </>
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
