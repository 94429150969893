/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mailFeedback = /* GraphQL */ `
  mutation mailFeedback($feedback: String!) {
    mailFeedback(feedback: $feedback)
  }
`;
export const sendCustomEmail = `
  mutation sendCustomEmail($email: String!, $subject: String!, $body: String!) {
    sendCustomEmail(email: $email, subject: $subject, body: $body)
  }
`;
export const CreateGuestUser = `
  mutation CreateGuestUser {
    createGuestUser {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`

export const signUpGuestUser = `
  mutation SignUpGuestUser($userInput: UserInputData!) {
    signUpGuestUser(userInput: $userInput) {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`;

export const createUser = `
  mutation CreateUser($userInput: UserInputData!) {
    createUser(userInput: $userInput) {
      token
      id
      locale
      user {
        id
        locale
        registered
        isGuest
      }
    }
  }
`;

export const registerUser = /* GraphQL */ `
  mutation RegisterUser($input: CreateUserInput!) {
    registerUser(input: $input) {
      id
      username
      email
      registered
      locale
      relationships {
        items {
          id
          createdAt
          updatedAt
          name
          animalId
          gender
          partnerAnimalId
          partnerGender
          relation
          partnerName
          owner {
            id
            username
            email
            registered
            locale
            relationships {
              items {
                id
                createdAt
                updatedAt
                name
                animalId
                gender
                partnerAnimalId
                partnerGender
                relation
                partnerName
              }
              nextToken
            }
            playlists {
              items {
                id
                createdAt
                name
                order
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          partnerRelationshipSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          partnerTriggerSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          relationshipSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          triggerSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          mySideMirrors {
            id
            createdAt
            updatedAt
            journeyProps
            journeyStepIds
          }
        }
        nextToken
      }
      playlists {
        items {
          id
          createdAt
          owner {
            id
            username
            email
            registered
            locale
            relationships {
              items {
                id
                createdAt
                updatedAt
                name
                animalId
                gender
                partnerAnimalId
                partnerGender
                relation
                partnerName
              }
              nextToken
            }
            playlists {
              items {
                id
                createdAt
                name
                order
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          videos {
            url
            start
            end
            title
          }
          name
          order
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const login = `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      id
      email
      locale
      user {
        id
        locale
        registered
        is1stFeedbackComplete
        isOnboardingComplete
      }
    }
}
`

export const mailResetPassword = `
  mutation MailResetPassword($email: String!) {
    mailResetPassword(email: $email)
  }
`

export const updateUserIsSubscribed = `
  mutation UpdateUserIsSubscribed($isSubscribed: Boolean!) {
    setUserSubscription(isSubscribed: $isSubscribed) {
      id
      isSubscribed
      __typename
    }
  }
`

export const updateUserExitPoll = /* GraphQL */ `
  mutation UpdateUserExitPoll($exitPoll: ExitPollInput!) {
    setUserExitPoll(exitPoll: $exitPoll) {
      id
      isSubscribed
      exitPoll {
        subscriptionLikelihood
        reasons
        explanation
      }
      __typename
    }
  }
`;

export const updateUserType = `
  mutation UpdateUserType($type: UserType!) {
    setUserType(type: $type) {
      id
      type
      __typename
    }
  }
`

export const updateUser1stFeedbackComplete = `
  mutation UpdateUserType($is1stFeedbackComplete: Boolean!) {
    setUserIs1stFeedbackComplete(is1stFeedbackComplete: $is1stFeedbackComplete) {
      id
      is1stFeedbackComplete
      __typename
    }
  }
`
export const updateUserOnboardingComplete = `
  mutation UpdateUserType($isOnboardingComplete: Boolean!) {
    setUserIsOnboardingComplete(isOnboardingComplete: $isOnboardingComplete) {
      id
      isOnboardingComplete
      __typename
    }
  }
`

export const updateUserProgress = `
mutation UpdateUserProgress($userProgress: JSON!) {
  setUserProgress(userProgress: $userProgress) {
    id
    userProgress
    __typename
  }
}
`;
export const updateUserFeedback = `
mutation UpdateUserFeedback($userFeedback: JSON!) {
  setUserFeedback(userFeedback: $userFeedback) {
    id
    userFeedback
    __typename
  }
}
`;
export const updateUserLog = `
mutation UpdateUserLog($userLog: JSON!) {
  setUserLog(userLog: $userLog) {
    id
    userLog
    __typename
  }
}
`;
export const updatePlaytime = `
mutation UpdatePlaytime($playtime: JSON!) {
  setPlaytime(playtime: $playtime) {
    id
    playtime
    __typename
  }
}
`;

  
export const talkToBummer = `
  mutation TalkToBummer($mirrorId: String!, $relationshipObject: String!, $newUserInput: String, $shouldRestart: Boolean) {
    talkToBummer(mirrorId: $mirrorId, relationshipObject: $relationshipObject, newUserInput: $newUserInput, shouldRestart: $shouldRestart) {
      id
      AISummary {
        role
        content
      }
    }
  }
`


export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      email
      registered
      locale
      isSubscribed
      userProgress
      userFeedback
      userLog
      playtime
      relationships {
        items {
          id
          createdAt
          updatedAt
          name
          animalId
          gender
          partnerAnimalId
          partnerGender
          relation
          partnerName
          owner {
            id
            username
            email
            registered
            locale
            relationships {
              items {
                id
                createdAt
                updatedAt
                name
                animalId
                gender
                partnerAnimalId
                partnerGender
                relation
                partnerName
              }
              nextToken
            }
            playlists {
              items {
                id
                createdAt
                name
                order
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          partnerRelationshipSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          partnerTriggerSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          relationshipSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          triggerSurvey {
            surveyFeeling {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyHappened {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
            surveyNeeds {
              cardType
              isReversed
              isReduced
              prefix
              modifier
              emoji
              isPositive
              title
              description
              switchSides
            }
          }
          mySideMirrors {
            id
            createdAt
            updatedAt
            journeyProps
            journeyStepIds
          }
        }
        nextToken
      }
      playlists {
        items {
          id
          createdAt
          owner {
            id
            username
            email
            registered
            locale
            relationships {
              items {
                id
                createdAt
                updatedAt
                name
                animalId
                gender
                partnerAnimalId
                partnerGender
                relation
                partnerName
              }
              nextToken
            }
            playlists {
              items {
                id
                createdAt
                name
                order
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          videos {
            url
            start
            end
            title
          }
          name
          order
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const summarizeMyPointOfView = `
  mutation SummarizeMyPointOfView($mirrorId: String!, $myPointOfViewTextSummary: String!) {
    summarizeMyPointOfView(mirrorId: $mirrorId, myPointOfViewTextSummary: $myPointOfViewTextSummary) {
      id
      AISummary
    }
  }
`

export const createRelationship = `
	mutation CreateRelationship($input: CreateRelationshipInput!) {
		createRelationship(input: $input) {
      id
      name
      animalId
      gender
      partnerAnimalId
      partnerGender
      relation
			partnerName
			partnerRelationshipSurvey {
				surveyFeeling {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
				surveyHappened {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
				surveyNeeds {
					cardType
					isReversed
					isReduced
					prefix
					modifier
					emoji
					isPositive
					title
					description
					switchSides
				}
			}
		}
	}
`;


export const updateRelationship = `
mutation UpdateRelationship($input: UpdateRelationshipInput!) {
  updateRelationship(input: $input) {
    id
    name
    animalId
    gender
    partnerAnimalId
    partnerGender
    relation
    partnerName
    partnerRelationshipSurvey {
      surveyFeeling {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      surveyHappened {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
      surveyNeeds {
        cardType
        isReversed
        isReduced
        prefix
        modifier
        emoji
        isPositive
        title
        description
        switchSides
      }
    }
  }
}
`;

export const updateMirror = `
  mutation UpdateMirror($input: updateMirrorInput!){
    updateMirror(input: $input){
      id
      relationshipId
      progress{
        journeyItem
        step
        version
        currentStepNumber
        flowState{
          isCompleted
        }
      }
      createdAt
      updatedAt
      journeyProps
      journeyStepIds
    }
  }
`;

export const addMirror = `
  mutation AddMirror($input: createMirrorInput!){
    addMirror(input: $input)
    {
      id
      relationshipId
      progress{
        journeyItem
        step
      }
      createdAt
      updatedAt
      journeyProps
      journeyStepIds
    }
  }
`;

export const deleteRelationship = /* GraphQL */ `
  mutation deleteRelationship($id: String) {
    deleteRelationship(id: $id)
  }
`;

export const deleteMirror = /* GraphQL */ `
  mutation deleteMirror($input: String) {
    deleteMirror(input: $input)
  }
`;

export const createPlaylist = /* GraphQL */ `
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              journeyProps
              journeyStepIds
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;

export const updatePlaylist = /* GraphQL */ `
  mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              journeyProps
              journeyStepIds
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;

export const deletePlaylist = /* GraphQL */ `
  mutation DeletePlaylist($input: DeletePlaylistInput!) {
    deletePlaylist(input: $input) {
      id
      createdAt
      owner {
        id
        username
        email
        registered
        locale
        relationships {
          items {
            id
            createdAt
            updatedAt
            name
            animalId
            gender
            partnerAnimalId
            partnerGender
            relation
            partnerName
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            partnerRelationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            partnerTriggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            relationshipSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            triggerSurvey {
              surveyFeeling {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyHappened {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
              surveyNeeds {
                cardType
                isReversed
                isReduced
                prefix
                modifier
                emoji
                isPositive
                title
                description
                switchSides
              }
            }
            mySideMirrors {
              id
              createdAt
              updatedAt
              journeyProps
              journeyStepIds
            }
          }
          nextToken
        }
        playlists {
          items {
            id
            createdAt
            owner {
              id
              username
              email
              registered
              locale
              relationships {
                nextToken
              }
              playlists {
                nextToken
              }
              createdAt
              updatedAt
            }
            videos {
              url
              start
              end
              title
            }
            name
            order
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      videos {
        url
        start
        end
        title
      }
      name
      order
      updatedAt
    }
  }
`;
