import { useState } from "react";

const handleJumpForward = (playerRef, progress, setProgress) => {
  const jumpTime = 3;
  playerRef.current?.seekTo(progress + jumpTime);
  setProgress(progress + jumpTime);
};

const handleJumpBackwards = (playerRef, progress, setProgress) => {
  const jumpTime = -3;
  playerRef.current?.seekTo(progress + jumpTime);
  setProgress(progress + jumpTime);
};

const handleReplay = (playerRef, url, setPlaying) => {
  setPlaying(true);
  const seekto = url.split("?t=")[1];
  if (seekto) {
    playerRef.current?.seekTo(parseInt(seekto));
  } else {
    playerRef.current?.seekTo(0);
  }
};

const handlePause = (setPlaying) => {
  console.log("onPause");
  setPlaying(false);
  removeOverlayTimeout();
};

const removeOverlayTimeout = () => {
  var element = document.getElementById("overlay");
  element.classList.remove("opacityFalse")
  element.classList.remove("Playtime_fade-out")
  element.classList.remove("cursorFalse")
  element.classList.remove("Playtime_fade-in")
}

const handlePlayTime = (
  setPlayTimeCountdownON,
  setPlayTimeCountdown,
  setCountDownCloseToFinish,
  setCountDownLastMinute,
  setPlayTimeOver,
  countDownLastMinute,
  countDownCloseToFinish,
  playTimeOver
) => {
  const input = prompt("How long would you like to play (in minutes)?", 30);
  const timeCloseToFinish = 5;
  if (input === null || isNaN(input)) return;

  const minutes = parseInt(input);
  alert("PlayTime set to " + minutes + " minutes");
  let seconds = minutes * 60;
  setPlayTimeCountdownON(true);

  const interval = setInterval(() => {
    seconds -= 1;
    const remainingMinutes = Math.ceil(seconds / 60);

    if (countDownLastMinute) {
      setPlayTimeCountdown(seconds);
    } else {
      setPlayTimeCountdown(remainingMinutes + "m");
    }

    if (remainingMinutes <= timeCloseToFinish && !countDownCloseToFinish) {
      setCountDownCloseToFinish(true);
    }
    if (remainingMinutes <= 1 && !countDownLastMinute) {
      setCountDownLastMinute(true);
    }
    if (remainingMinutes <= 0 && !playTimeOver) {
      alert("playtime over!");
      setPlayTimeOver(true);
      clearInterval(interval);
    }
  }, 1000);
};

const handleClickFullscreen = (fullscreen, setFullscreen) => {
  setFullscreen(!fullscreen);
  if (!fullscreen) {
    document.getElementById("tofu").requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

const handlePlayPause = (playing, setPlaying) => {
  setPlaying(!playing);
  handleFadeOutMainIcon();
};

const handleFadeOutMainIcon = () => {
  const element = document.getElementById("overlay__main-icon");
  if (element) {
    element.classList.remove("Playtime_fade-in-out");
    void element.offsetWidth;
    element.classList.add("Playtime_fade-in-out");
  }
};

const handleMouseMoveOverVideoTimout = (
  playing,
  mouseMovingOverVideo,
  setMouseMovingOverVideo
) => {
  const element = document.getElementById("overlay");
  if (!mouseMovingOverVideo && playing) {
    setMouseMovingOverVideo(true);
    handleHideControlsTimeout(playing, setMouseMovingOverVideo);
  } else if (playing && element) {
    element.classList.remove("opacityFalse", "Playtime_fade-out", "cursorFalse");
    element.classList.add("Playtime_fade-in");
  }
};

const handleHideControlsTimeout = (playing, setMouseMovingOverVideo) => {
  const element = document.getElementById("overlay");
  setTimeout(() => {
    setMouseMovingOverVideo(false);
    if (playing && element) {
      element.classList.add("opacityFalse", "cursorFalse", "Playtime_fade-out");
      element.classList.remove("Playtime_fade-in");
    }
  }, 3000);
};

export {
  handleJumpForward,
  handleJumpBackwards,
  handleReplay,
  handlePlayTime,
  handleClickFullscreen,
  handlePlayPause,
  handleFadeOutMainIcon,
  handleMouseMoveOverVideoTimout,
  handleHideControlsTimeout,
  handlePause
};