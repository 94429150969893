import React, { useContext } from "react";
import "./ToggleSwitch.scss";
import { GlobalContext } from "context/GlobalContext";

const ToggleSwitch = ({settingsToggle, settingsToggleTwoSidesSame, onToggle, isSFX }) => {
    
    
    const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);
    const soundTap = () => {
        new Audio('/assets/audio/tap.mp3').play();
    }

    const handleToggle = () => {
        setVolumeSwitchState(!VolumeSwitchState);
        if (isSFX) {
          if (!VolumeSwitchState) soundTap();
        } else {
            onToggle && onToggle();
        }
      };

    return (
        <>
            <div onClick={handleToggle} className={" toggleSwitch_check-box " + (settingsToggle && " settingsToggle ") + (settingsToggleTwoSidesSame && " settingsToggleTwoSidesSame ") }>
                <input
                    className="toggleSwitch_input"
                    checked={VolumeSwitchState}
                    type="checkbox"
                    onChange={handleToggle} // Add onChange handler
                />
            </div>
        </>
    );
}

export default ToggleSwitch;
