/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import HomeIcon from "./home_icon.png";
import Drawer from "@material-ui/core/Drawer";
import FeltLogo from "../../components/FeltLogo/FeltLogo";
import Loading from "../../components/Loading/Loading";
import LogoCatbears from "../../components/LogoCatbears/LogoCatbears";
import "./TopNavbar.scss";
import ButtonCircle from "components/ButtonCircle/ButtonCircle";
import { GlobalContext } from 'context/GlobalContext';


function changeLanguage(i18n, langId) {
  if (!langId) {
    if (i18n.language === `en` || !i18n.language) {
      langId = `he`;
    } else {
      langId = `en`;
    }
  }
  i18n.changeLanguage(langId);
  document.body.setAttribute(`lang`, langId);
  return langId;
}

function TopNavbar({
  opacity,
  setVolumeSwitchState,
  VolumeSwitchState,
  hideLogoOnMobile,
  border,
  title,
  showNavBG,
  texture,
  removeNavStrip,
  backgroundColor,
  showLogo,
  promptCancelChances,
  showSettingsButton,
  showMenuButton,
  showHomeButton,
  BackLinkTo,
  rightButton,
  brightNav,
  BackOnClick,
  changeStep,
  spacer,
  user,
  authContext,
  showLangButton,
  showFeedbackModalButton,
  openFeedbackPopup,
  setIsFeedbackModalState
}) {
  const [navbarState, setNavbarState] = useState({
    isMenuOpen: false,
    opacity,
    title,
    showNavBG,
    texture,
    removeNavStrip,
    showMenuButton,
    showHomeButton,
    BackLinkTo,
    showLogo,
    brightNav,
    showSettingsButton,
    showLangButton,
    showFeedbackModalButton,
    backgroundColor,
  });

  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const history = useHistory(); 
  const {IsPWallOpen, setIsPWallOpen} = useContext(GlobalContext);

  const { t, i18n } = useTranslation();
  const historyHook = useHistory();

  document.documentElement.style.setProperty('--topNavbar_background-color', backgroundColor);
  document.documentElement.style.setProperty('--topNavbar_opacity', opacity);

  const localeHandler = (i18n) => {
    const langId = changeLanguage(i18n);
    authContext.changeLanguage(langId);
  };

  const handleLanguageClick = () => {
    history.push('/language');
  };
  
  const removeBorder = () => {
    return border === false ? { borderBottom: 'none' } : {};
  };

  const gotoLogin = () => {
    historyHook.push('/login');
  }
  const gotoRegister = () => {
    historyHook.push('/register');
  }

  const handlePromptCancelChanges = () => {
    const message = t(`general.unsavedChanges`);
    if (window.confirm(message)) {
      historyHook.push(BackLinkTo);
    }
  };

  const toggleDrawer = (open) => (event) => {
    setNavbarState({ ...navbarState, isMenuOpen: open });
  };

  const center = (() => {
    if (showLogo) {
      return (
        <div className="top-navbar--center noselect">
          <FeltLogo color="orange" />
        </div>
      );
    } else if (title) {
      return (
        <h1 className={brightNav ? "top-navbar-title__bright noselect" : "top-navbar-title noselect"}>
          {title}
        </h1>
      );
    } else {
      return <div className="top-navbar--spacer noselect"></div>;
    }
  })();

  const leftSide = (() => {
    return (
      <div className="top-navbar--leftSide noselect">
        {showMenuButton && (
          <div className="top-navbar--menu top-navbar--circle__with-text noselect" onClick={toggleDrawer(true)}>
            <span className="textWebNav">{t(`topnavbar.menu.title`)}</span> &#9776;
          </div>
        )}
        {showHomeButton && (
          <Link to={{ pathname: `/` }}>
            <div className="top-navbar--circle noselect">
              <img src={HomeIcon} alt="home icon" width="33" height="30" />
            </div>
          </Link>
        )}
        {showFeedbackModalButton && (
          // <a href="https://forms.gle/x5de7YCEMnrsWSJ66" target="_blank" rel="noopener noreferrer">
            <div onClick={openFeedbackPopup} className="top-navbar--menu top-navbar--circle__with-text top-navbar--feedback noselect">
              <img className="icon-feedback noselect" src={"/assets/icons/icon-feedback.png"} />
              <span className="textWebNav noselect">{t('general.feedback')}</span>
            </div>
          // </a>
        )}
        {BackOnClick && (
          <div className="top-navbar--circle noselect" onClick={BackOnClick}>
            <span className="left-chevron">&#8249;</span>
          </div>
        )}
        {BackLinkTo && (
          <div>
            {promptCancelChances ? (
              <div className="top-navbar--circle noselect" onClick={handlePromptCancelChanges}>
                <span className="left-chevron">&#8249;</span>
              </div>
            ) : (
              <ButtonCircle
              classes={'ButtonCircle-back ButtonCircle-50px '}
              // onClick={()=> {alert("pause!")}}
              to={BackLinkTo}
              />
            )}
          </div>
        )}
      </div>
    );
  })();

  const rightSide = (() => {
    return (
      <div className="top-navbar--rightSide noselect">
        <LogoCatbears 
          isSoundSwitch
          hideLogoOnMobile={hideLogoOnMobile && hideLogoOnMobile}
          isFeedbackBtn
          isHovering
        />
        {rightButton && (
          <div>
            <Button
              classes={`top-navbar__button noselect ${rightButton.color ? "top-navbar__button--color" : ""}`}
              text={rightButton.text}
              onClick={rightButton.onClick}
            />
          </div>
        )}
        {showSettingsButton && (
          <div>
            <div className="top-navbar--circle noselect" onClick={showSettingsButton.onClick}>
              <span className="settings-icon">⋮</span>
            </div>
          </div>
        )}
        {spacer && <div className="top-navbar--spacer noselect"></div>}
      </div>
    );
  })();

  return (
    <>
      {isLoadingState && <Loading />}
      <div className={`top-navbar noselect ${showNavBG ? "top-navbar__bg" : ""} ${removeNavStrip ? "top-navbar-strip--remove" : ""}`} style={removeBorder()}>
        {leftSide}
        {center}
        {rightSide}
        <Drawer
          open={navbarState.isMenuOpen}
          anchor={i18n.language === 'en' ? 'left' : 'right'}
          onClose={toggleDrawer(false)}
        >
          <div className="menu">
            <div className="menu__nav noselect">
              <h4>{t(`topnavbar.menu.title`)}</h4>
              <p onClick={toggleDrawer(false)}>+</p>
            </div>
            <div className="menu__list">
              <div className="menu__list--top">
                {/* {console.log("user.isGuest ", user.isGuest)} */}
                {user.isGuest && 
                    <div className="menu__links noselect">
                        <p>{t("loginFlow.sub-title-to-login")}</p>
                        <a onClick={gotoRegister}>{t("loginFlow.createAnAccount")}</a>
                        <p>{t("loginFlow.logInCatbears")}</p>
                        <a onClick={gotoLogin}>{t("loginFlow.logIn")}</a>
                        </div>
                }
                {/* <div className="menu__links noselect">
                  <a href="./hiw">How Felt Works</a>
                </div>
                <div className="menu__links noselect">
                  <a href="./faq">Frequently Asked Questions</a>
                </div> */}
              </div>
              <div className="menu__list--bottom">
                <div className="menu__links noselect">
                  {/* <a href="https://catbears.com/felt/terms/" rel="noopener noreferrer">
                    {t(`topnavbar.menu.terms_and_conditions_button_text`)}
                  </a>
                  <a href="https://catbears.com/felt/privacy/" rel="noopener noreferrer">
                    {t(`topnavbar.menu.privacy_policy_button_text`)}
                  </a> */}

                  {/* ️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥️‍🔥  */}
                 {/* {!user.isSubscribed && (
                    <a onClick={() => {
                      setIsPWallOpen(true)
                      console.log("IsPWallOpen ", IsPWallOpen)
                      }}>
                      <div>הרשמו לדובתולים</div>
                    </a>
                  )}  */}
                  
                  {!user.isGuest && (
                    <a href="/" onClick={() => authContext.logout()}>
                      <div>{t(`topnavbar.menu.logout_button_text`)}</div>
                      <span className="user-email">{user.email}</span>
                    </a>
                  )}

                  
                  
                     {/* <a href="/" onClick={() => authContext.logout()}>
                      <div>{t(`topnavbar.menu.logout_button_text`)}</div>
                      <span className="user-email">{user.email}</span>
                    </a> */}
                  
                </div>
                <div className="menu__footer noselect">
                  <p>{t(`topnavbar.menu.copyright`)}</p>
                  {showLangButton && (
                    <div className="menu__footer--lang noselect" onClick={handleLanguageClick}>
                      <span className="settings-icon">{t(`topnavbar.menu.change_language_button_text`)}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default TopNavbar;
