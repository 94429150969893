import { useEffect } from "react";
import "./PayPalSubscriptionButtons.scss"

export default function PayPalMonthlyButtons({setSubscriptionID}) {

  const isSANDBOX = true;
  const LiveAppID = "AXMXyigBsNDHBdwurI7GIv7qH6L7x30t_qXj1WmNlhav8-ogBDRB78GkSlKQi509oA9QBZlpmDwk2I-7"
  const SandboxAppID = "AdjPvvaKF7JWBO01I68xQUO1xd2TBggga6P4uRuLEqxzstxTD-pvsMnI-0xsZyLzYzruYDhciSySKNgc"
  const LivePlanID = "P-8NC72863CG437894DM7R5ENY"
  const SandboxPlanID = "P-9TH42844RM712802XM7SSTSI"
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = ("https://www.paypal.com/sdk/js?client-id=" + (isSANDBOX ? SandboxAppID : LiveAppID) + "&vault=true&intent=subscription");
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.addEventListener("load", () => {
      if (window.paypal) {
        window.paypal.Buttons({
          style: {
            shape: "pill",
            color: isSANDBOX ? "white" : "gold",
            layout: "vertical",
            label: "paypal",
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: (isSANDBOX ? SandboxPlanID : LivePlanID),
            });
          },
          onApprove: function (data, actions) {
            // alert(data.subscriptionID);
            console.log("data.subscriptionID ", data.subscriptionID)
            setSubscriptionID(data.subscriptionID)
          },
        }).render("#paypal-button-container-" + (isSANDBOX ? SandboxPlanID : LivePlanID));
      }
    });
    document.body.appendChild(script);
  }, []);

  return <><div className="PayPalSubscriptionButtons " id={"paypal-button-container-" + (isSANDBOX ? SandboxPlanID : LivePlanID)}></div></>
}

// after sandbox purchase: I-RVD94NR9A71D
// I-0B8G9D8E5C1A