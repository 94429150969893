import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {login} from "../../graphql/mutations";
import Register from "./Register";
import Modal from "../../components/Modal/Modal";
import UserContext from "./UserContext";
import RestorePassword from "./RestorePassword";
import Catbears_Login from './Catbears_Login';
import Catbears_Register from './Catbears_Register';
import { useHistory } from 'react-router-dom';
import LogoCatbears from 'components/LogoCatbears/LogoCatbears';
import "./LoginPage.scss"

const RestorePasswordPage = ({onLogin, onClose, shouldAllowSwitchToRegister, getIsRegisterMode=false}) => {
  const {user, handleUserUpdate, saveUserToLocalStorage, setAuthData} = useContext(UserContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegisterMode, setIsRegisterMode] = useState(getIsRegisterMode);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [shouldDisplayLogin, setshouldDisplayLogin] = useState(true);
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);

  const {t} = useTranslation();

  return (
    <>
      <LogoCatbears 
            isCentered 
            isTapToLandingPage
        /> 
      <div className={"Onboarding-content Onboarding-container-no-footer login-page-container hideScrollbar"}>
            <RestorePassword onSwitchToLogin={() => setIsResetPassword(false)} initialEmail={email}/>
        <div className='Room'></div>
      </div>
    </>
  )
}


export default RestorePasswordPage;


