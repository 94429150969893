import gql from 'graphql-tag';
import React ,{useState, useEffect, useRef, useContext} from 'react';
import ReactPlayer from 'react-player';
import "./Playtime.scss";
import {updatePlaytime} from '../../graphql/mutations';
import {useMutation} from 'react-apollo';
import UserContext from "../../pages/Login/UserContext";

import OverlayMainVideo from './PlaytimeComps/OverlayMainVideo';
import Category from './Category';
import { 
  handlePlayPause,
  handleMouseMoveOverVideoTimout,
  handlePlayTime,
  handleClickFullscreen,
  handleReplay,
  handleJumpBackwards,
  handleJumpForward,
  handlePause
  } from './PlaytimeUtils/playtimeFunctions';

function Playtime ({user}){
  const {handleUserUpdate} = useContext(UserContext);

  const playerRef = useRef();
  const [url, setUrl] = useState("https://youtu.be/qlpgtVk7KK8");
  const [urlCurrentlyPlaying, setUrlCurrentlyPlaying] = useState("");
  const [playing, setPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState();
  const [rightMenu, setRightMenu] = useState(false);
  const [playTimeCountdown, setPlayTimeCountdown] = useState("");
  const [playTimeCountdownON, setPlayTimeCountdownON] = useState(false);
  const [countDownCloseToFinish, setCountDownCloseToFinish] = useState(false);
  const [countDownLastMinute, setCountDownLastMinute] = useState(false);
  const [playTimeOver, setPlayTimeOver] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [Played, setPlayed] = useState(0);
  const [Loaded, setLoaded] = useState(0);
  const [Pip, setPip] = useState(false);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(null);
  const [currentVideoDuration, setCurrentVideoDuration] = useState(0);
  const [videoWatched, setVideoWatched] = useState(0);
  const [startFrom, setStartFrom] = useState(0);
  const [playtimeFirstLoaded, setPlaytimeFirstLoaded] = useState(false);

  // console.log("PLAYTIME user ", user)

  const listDefault = [ // Videos
    "RECOMMENDED__https://youtu.be/Me3qzAODv2o", // כספיון
    "https://youtu.be/Rrbkur70Sbg" , // בלי סודות
    "https://youtu.be/0A0WhTkY_i8?t=11", // ״הזחל הרעב״
    "https://youtu.be/EvU1EzELJI8", // דני שובבני
    "https://www.youtube.com/watch?v=kn9ryAm3kaI", // מיץ פטל
  ]
  const [list, setList] = useState(listDefault);


  const defaultPlaytime = {
    version: 1,
    categories: [
      {
        name: "כיף כיף כיף",
        color: "yellow",
        videosList: [
          {
            name: "כספיון",
            url: "https://youtu.be/Me3qzAODv2o",
            recommended: true
          }
        ]
      },
      {
        name: "ללמוד משהו חדש",
        color: "red",
        videosList: [
          {
            name: "איך מכינים מחזור חיים של פרפר",
            url:"https://www.youtube.com/watch?v=QCk8e7z8d3g",
            recommended: true
          },
        ]
      }
    ],
    archive: [
      {
        name: "ארכיון",
        color: "red",
        videosList: [
          {
            name: "Rednex - Cotton Eye Joe (Official Music Video) [HD] - RednexMusic com",
            url: "https://www.youtube.com/watch?v=mOYZaiDZ7BM&ab_channel=RednexVideos",
            recommended: false
          },
          {
            name: "Hanson - MMMBop (Official Music Video)",
            url:"https://www.youtube.com/watch?v=NHozn0YXAeE&ab_channel=HansonVEVO",
            recommended: true
          },
        ]
      }
    ]
  };


  const videoListDefault = [
    {
      name: "כספיון",
      url: "https://youtu.be/Me3qzAODv2o",
      recommended: true
    },
    {
      name: "איך מכינים מחזור חיים של פרפר",
      url:"https://www.youtube.com/watch?v=QCk8e7z8d3g",
      recommended: true
    },
  ]


  const [playtimeData, setPlaytimeData] = useState(defaultPlaytime);
  const [updatePlaytimeData, {data, loading, error}] = useMutation(gql(updatePlaytime), {
        update(cache, {data: {setPlaytime}}) {
          if (!setPlaytime) {
            console.error('setPlaytime is undefined or null');
            return;
          }
          handleUserUpdate(setPlaytime);
        },
      });

  const updatePlaytimeEvent = async (data) => {
    await updatePlaytimeData({
      variables: {
        playtime: data
      },
    });
  };

  const clearPlaytime = async () => {
    const previous = user.playtime;
    await updatePlaytimeData({
      variables: {
        playtime: "__CLEAR__",
      },
    });
    user.playtime = previous; // prevent triggering useEffect logic
  };
  

  useEffect(() => {
    if (
      user?.playtime === "" ||
      user?.playtime === null ||
      user?.playtime === "__CLEAR__"
    ) {
      updatePlaytimeEvent(playtimeData);
      return;
    }
  
    try {
      const parsed = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      if (!parsed || Object.keys(parsed).length === 0) {
        updatePlaytimeEvent(playtimeData);
      } else {
        setPlaytimeData(parsed);
      }
    } catch (e) {
      updatePlaytimeEvent(playtimeData);
    }
  }, [user]);
  
  useEffect(() => {
    if (!url || currentCategoryIndex === null || !videoDuration || isNaN(videoDuration) || videoDuration === 0) return;
  
    console.log("videoDuration ", videoDuration)

    const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
    const updated = JSON.parse(JSON.stringify(current));
    const category = updated.categories?.[currentCategoryIndex];
    if (!category) return;
  
    const video = category.videosList.find(v => v.url === url);
    if (!video || video.duration) return;
  
    video.duration = Math.round(videoDuration);
    updatePlaytimeEvent(updated);
  }, [videoDuration, url, currentCategoryIndex]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (!url || currentCategoryIndex === null || !videoWatched) return;
  
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = structuredClone(current);
      const category = updated.categories?.[currentCategoryIndex];
      const video = category?.videosList.find(v => v.url === url);
      if (!video || !video.duration || !playing) return;
      video.watched = videoWatched >= video.duration - 2 ? 0 : Math.round(videoWatched);
      updatePlaytimeEvent(updated);
    }, 1000);

    return () => clearInterval(interval);


  }, [videoWatched, url, currentCategoryIndex]);


  const handleRightMenu = () => {
    setRightMenu(!rightMenu)
  };
  
  const handleEditMode = () => {
    setEditMode(!editMode);
    if(editMode){
      setRightMenu(true);
      handlePause(setPlaying);
      // this.setState({ state: this.state });
    };
  }

  const loadURL = (url, categoryIndex) => {

    if (urlCurrentlyPlaying == url) return;

    setCurrentCategoryIndex(categoryIndex);
    const category = playtimeData.categories?.[categoryIndex];
    const video = category?.videosList.find(v => v.url === url);
    if (!video) return;
    
    const watched = video?.watched || 0;
    setUrl(url); // only after watched is set
    setStartFrom(watched);
    setUrlCurrentlyPlaying(url)

  };
  
  
  const printUser = () => {
    console.log("user ", user)
  }

  const getVideoTitle = async (videoId) => {
    try {
      const res = await fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${videoId}`);
      const data = await res.json();
      return data.title || "name";
    } catch (e) {
      console.error("Failed to fetch video title:", e);
      return "name";
    }
  };
  
  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
  
    return hrs > 0
      ? `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
      : `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  

  const getVideoDuration = async (url) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.src = `https://www.youtube.com/embed/${url.split("v=")[1]}?autoplay=0`;
      video.addEventListener("loadedmetadata", () => {
        resolve(Math.round(video.duration));
      });
      video.addEventListener("error", () => {
        resolve(null);
      });
    });
  };

  const handleAddVideo = async (index) => {
    let clipboardText = "";
    try {
      clipboardText = await navigator.clipboard.readText();
    } catch (e) {
      console.warn("Clipboard not accessible:", e);
    }
  
    const input = prompt("Add a video:", clipboardText || "Enter URL");
    if (!input || input === "Enter URL") return;
  
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = input.match(regExp);
    if (!match || match[2].length !== 11) return;
  
    const videoId = match[2];
    const url = `https://www.youtube.com/watch?v=${videoId}`;
    const duration = await getVideoDuration(url);
    const title = await getVideoTitle(videoId);
  
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = structuredClone(current);
  
      if (!updated.categories || !updated.categories[index]) return;
  
      updated.categories[index].videosList.unshift({
        name: title || "name",
        url,
        duration,
        recommended: false,
        color: "yellow"
      });
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to update playtime:", e);
    }
  };
  
  

  const handleSaveDuration = (duration, videoUrl, categoryIndex) => {
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const existingCategory = current?.categories?.[categoryIndex];
      const existingVideo = existingCategory?.videosList?.find(v => v.url === videoUrl);
  
      if (!existingVideo || existingVideo.duration) return;
  
      const updated = JSON.parse(JSON.stringify(current)); // עותק עמוק
      const category = updated.categories?.[categoryIndex];
      const video = category?.videosList?.find(v => v.url === videoUrl);
      if (!video) return;
  
      video.duration = Math.round(duration);
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to save duration:", e);
    }
  };
  
  
  const handleDeleteVideo = (videoIndex) => {
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = JSON.parse(JSON.stringify(current)); // deep clone
  
      if (!updated.archive?.[0]?.videosList) return;
  
      updated.archive[0].videosList.splice(videoIndex, 1);
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to delete video from archive:", e);
    }
  };
  

  const handleArchiveVideo = (categoryIndex, videoIndex) => {
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = JSON.parse(JSON.stringify(current)); // Deep clone
  
      const category = updated.categories?.[categoryIndex];
      if (!category || !category.videosList?.[videoIndex]) return;
  
      const video = category.videosList[videoIndex];
  
      // Remove from original category
      updated.categories[categoryIndex].videosList.splice(videoIndex, 1);
  
      // Add to archive
      if (!updated.archive) updated.archive = [];
      if (!updated.archive[0]) {
        updated.archive[0] = {
          name: "ארכיון",
          color: "red",
          videosList: [],
        };
      }
      updated.archive[0].videosList.unshift(video);
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to archive video:", e);
    }
  };
  
  
  const handleEditCategoryName = (categoryName, categoryIndex) => {
    const newName = prompt("Edit category name:", categoryName || "");
    if (newName === null) return;
  
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = { ...current };
  
      if (!updated.categories || !updated.categories[categoryIndex]) return;
      updated.categories[categoryIndex].name = newName;
  
      updatePlaytimeEvent(JSON.stringify(updated));
    } catch (e) {
      console.error("Failed to update category name:", e);
    }
  };
  
  const handleAddCategory = () => {
    const input = prompt("Category name:", "קטגוריה חדשה");
    if (!input) return;
  
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = structuredClone(current);
  
      updated.categories.unshift({
        name: input,
        color: "yellow",
        videosList: []
      });
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to add category:", e);
    }
  };
  
  const handleMoveCategoryUp = (index) => {
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = structuredClone(current);
      if (index === 0) return;
  
      const temp = updated.categories[index - 1];
      updated.categories[index - 1] = updated.categories[index];
      updated.categories[index] = temp;
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to move category up:", e);
    }
  };

  const handleMoveCategoryDown = (index) => {
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = structuredClone(current);
      if (index === updated.categories.length - 1) return;
  
      const temp = updated.categories[index + 1];
      updated.categories[index + 1] = updated.categories[index];
      updated.categories[index] = temp;
  
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to move category down:", e);
    }
  };

  const handleDeleteCategory = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this category?");
    if (!confirmDelete) return;
  
    try {
      const current = typeof user.playtime === "string" ? JSON.parse(user.playtime) : user.playtime;
      const updated = JSON.parse(JSON.stringify(current)); // deep clone
  
      if (!updated.categories || !updated.categories[index]) return;
  
      updated.categories.splice(index, 1);
      updatePlaytimeEvent(updated);
    } catch (e) {
      console.error("Failed to delete category from playtime:", e);
    }
  };
  



  return (
    <>
    <div>
      <div id="playTimeOver" className={playTimeOver ? " " : "displayNone" }>
        <div className="playTimeOver__container">
          <div className="frog-container"><div className="frog"></div></div>
            <h1>PlayTime is Over!</h1>
            <a src="/mytime"><div>My Time</div></a>
            {/* <Button
              isLink={true}
              to={{
                pathname: `/mytime`,
                }}
                text="MyTime"
                />  */}
        </div>
      </div>
      <div id='tofu'>
        {/* <div className="FLOATING_CHEATS noselect">
          <div onClick={clearPlaytime} className="cheats-button">CLEAR PLAYTIME</div>
          <div className={"cheats-button"} onClick={printUser}>LOG USER</div>
        </div> */}
        <div className="tofu__container">
          <div id="tofu__video">
            <div className="tofu__video--player">
              <OverlayMainVideo 
                playing={playing} 
                setPlaying={setPlaying} 
                url={url}
                playerRef={playerRef}
                rightMenu={rightMenu}
                handleRightMenu={handleRightMenu}
                setPlayTimeCountdownON = {setPlayTimeCountdownON}
                playTimeCountdownON={playTimeCountdownON}
                countDownCloseToFinish={countDownCloseToFinish}
                countDownLastMinute={countDownLastMinute}
                setCountDownLastMinute={setCountDownLastMinute}
                playTimeOver={playTimeOver}
                setPlayTimeOver={setPlayTimeOver}
                setCountDownCloseToFinish={setCountDownCloseToFinish}
                videoDuration={formatDuration(videoDuration)}
                userEmail={user.email ? user.email : undefined}
                videoWatched={formatDuration(videoWatched)}
                />
              <div className="player-wrapper">
                <ReactPlayer
                  ref={playerRef}
                  onReady={() => {
                    if (startFrom && playerRef.current) {
                      playerRef.current.seekTo(startFrom);
                    }
                    if (playtimeFirstLoaded) {
                      setPlaying(true);
                    } else {
                      setPlaytimeFirstLoaded(true);
                    }
                  }}
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={url}
                  config={{
                    youtube: {
                      playerVars: {
                        cc_load_policy: 0 // captions OFF
                      }
                    }
                  }}
                  playing={playing}
                  controls
                  onEnded={() => setPlaying(false)}
                  onDuration={(duration) => {
                    setVideoDuration(duration);
                    setCurrentVideoDuration(duration)
                    handleSaveDuration(duration, url, currentCategoryIndex);
                  }}
                  onProgress={({ playedSeconds }) => {
                    setVideoWatched(playedSeconds);
                  }}
                />
              </div>
            </div>
          </div>
        <div className={"tofu__rightMenu Playtime_fade-in " + (rightMenu ? "" : "hide")}>
          <div className="tofu__rightMenu--nav">
            <div className={"controls__btn controls__btn--edit " + (editMode ? "controls__btn--edit--on" : "controls__btn--edit--off")} onClick={()=>handleEditMode(setPlaying)} >{editMode ? "סיום" : "עריכה"}</div>
            {editMode && <div className={" controls__btn controls__btn--edit " } onClick={handleAddCategory} >+ קטגוריה</div>}
          </div>
          <div className={"tofu__rightMenu--lists"}>
          {playtimeData.categories?.map((category, index) => (
              <Category 
                isArchive={false}
                key={index}
                categoryIndex={index}
                editMode={editMode}  
                videosList={category.videosList}
                categoryName={category.name}
                colorClass={"rightMenu__list--skyblue"}
                loadURL={loadURL}
                handleAddVideo={handleAddVideo}
                handleArchiveVideo={handleArchiveVideo}
                handleEditCategoryName={handleEditCategoryName}
                handleMoveCategoryUp={handleMoveCategoryUp}
                handleMoveCategoryDown={handleMoveCategoryDown}
                handleDeleteCategory={handleDeleteCategory}
                formatDuration={formatDuration}

              />
            ))}

            <Category 
                isArchive
                categoryIndex={99999999}
                editMode={editMode}  
                videosList={playtimeData.archive[0].videosList}
                categoryName={playtimeData.archive[0].name}
                colorClass={"rightMenu__list--red"}
                loadURL={loadURL}
                handleAddVideo={handleAddVideo}
                handleDeleteVideo={handleDeleteVideo}
                handleEditCategoryName={handleEditCategoryName}
                handleMoveCategoryUp={handleMoveCategoryUp}
                handleMoveCategoryDown={handleMoveCategoryDown}
                handleDeleteCategory={handleDeleteCategory}
                formatDuration={formatDuration}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Playtime;