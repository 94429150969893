export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: object;
};

export type AuthData = {
  __typename?: 'AuthData';
  token: Scalars['String'];
  id: Scalars['String'];
  email: Scalars['String'];
  locale: Scalars['String'];
  user: User;
};

export type Card = {
  __typename?: 'Card';
  cardType?: Maybe<CardType>;
  isReversed?: Maybe<Scalars['Boolean']>;
  isReduced?: Maybe<Scalars['Boolean']>;
  prefix?: Maybe<Scalars['String']>;
  modifier?: Maybe<CardModifier>;
  emoji: Scalars['String'];
  isPositive: Scalars['Boolean'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  switchSides?: Maybe<Scalars['Boolean']>;
};

export enum CardModifier {
  None = 'none',
  IsReversed = 'isReversed',
  IsReduced = 'isReduced'
}

export enum CardType {
  Happened = 'HAPPENED',
  Feel = 'FEEL',
  Need = 'NEED',
  UserType = 'USER_TYPE'
}

export type ChangeLocale = {
  locale?: Maybe<Locale>;
};

export type CreateCard = {
  cardType?: Maybe<CardType>;
  isReversed?: Maybe<Scalars['Boolean']>;
  isReduced?: Maybe<Scalars['Boolean']>;
  prefix?: Maybe<Scalars['String']>;
  modifier?: Maybe<CardModifier>;
  emoji: Scalars['String'];
  isPositive: Scalars['Boolean'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  switchSides?: Maybe<Scalars['Boolean']>;
};

export type CreateMySideMirror = {
  id: Scalars['ID'];
  relationshipId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  journeyProps: Scalars['JSON'];
  progress?: Maybe<ProgressInputData>;
};

export type CreatePlaylist = {
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  owner: CreateUser;
  videos?: Maybe<Array<Maybe<CreateVideo>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateRelationshipInput = {
  name: Scalars['String'];
  animalId: Scalars['String'];
  gender?: Maybe<Gender>;
  partnerAnimalId: Scalars['String'];
  partnerGender?: Maybe<Gender>;
  relation?: Maybe<Relation>;
  partnerName: Scalars['String'];
  partnerRelationshipSurvey?: Maybe<CreateSurvey>;
  partnerTriggerSurvey?: Maybe<CreateSurvey>;
  relationshipSurvey?: Maybe<CreateSurvey>;
  triggerSurvey?: Maybe<CreateSurvey>;
};

export type CreateSurvey = {
  surveyFeeling?: Maybe<Array<Maybe<CreateCard>>>;
  surveyHappened?: Maybe<Array<Maybe<CreateCard>>>;
  surveyNeeds?: Maybe<Array<Maybe<CreateCard>>>;
};

export type CreateUser = {
  _id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  registered?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Locale>;
  relationships?: Maybe<Array<Maybe<CreateRelationshipInput>>>;
  playlists?: Maybe<Array<Maybe<CreatePlaylist>>>;
};

export type CreateVideo = {
  url?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export type IsCompleted = {
  __typename?: 'IsCompleted';
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type IsCompletedInput = {
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export enum Locale {
  En = 'en',
  He = 'he'
}

export type Mirror = {
  __typename?: 'Mirror';
  id: Scalars['ID'];
  relationshipId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  journeyProps: Scalars['JSON'];
  progress: ProgressData;
};

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  owner: User;
  videos?: Maybe<Array<Maybe<Video>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type PostInputData = {
  title: Scalars['String'];
  content: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ProgressData = {
  __typename?: 'ProgressData';
  journeyItem?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  currentStepNumber?: Maybe<Scalars['Int']>;
  flowState?: Maybe<Array<Maybe<Array<Maybe<IsCompleted>>>>>;
};

export type ProgressInputData = {
  journeyItem?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
  currentStepNumber?: Maybe<Scalars['Int']>;
  flowState?: Maybe<Array<Maybe<Array<Maybe<IsCompletedInput>>>>>;
};

export enum Relation {
  Parent = 'parent',
  Friend = 'friend',
  Lover = 'lover',
  Mom = 'mom',
  Dad = 'dad',
  Sister = 'sister',
  Brother = 'brother',
  InFamlity = 'inFamlity',
  BestFriend = 'bestFriend',
  Partner = 'partner',
  Teacher = 'teacher',
  SchoolMember = 'schoolMember',
  Boss = 'boss',
  WorkMember = 'workMember',
  Stranger = 'stranger'
}

export type Relationship = {
  __typename?: 'Relationship';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  animalId: Scalars['String'];
  gender?: Maybe<Gender>;
  partnerAnimalId: Scalars['String'];
  partnerGender?: Maybe<Gender>;
  relation?: Maybe<Relation>;
  partnerName: Scalars['String'];
  owner: User;
  partnerRelationshipSurvey?: Maybe<Survey>;
  partnerTriggerSurvey?: Maybe<Survey>;
  relationshipSurvey?: Maybe<Survey>;
  triggerSurvey?: Maybe<Survey>;
  mySideMirrors?: Maybe<Array<Maybe<Mirror>>>;
};

export type ResetPasswordReq = {
  email: Scalars['String'];
};

export type ServerMutations = {
  __typename?: 'ServerMutations';
  createUser?: Maybe<AuthData>;
  changeLocale?: Maybe<User>;
  createRelationship?: Maybe<Relationship>;
  updateRelationship?: Maybe<Relationship>;
  deleteRelationship?: Maybe<Scalars['Boolean']>;
  updateMirror?: Maybe<Mirror>;
  addMirror?: Maybe<Mirror>;
  deleteMirror?: Maybe<Scalars['Boolean']>;
  updateStatus: User;
  mailResetPassword?: Maybe<Scalars['String']>;
  changePassword?: Maybe<AuthData>;
};


export type ServerMutationsCreateUserArgs = {
  userInput?: Maybe<UserInputData>;
};


export type ServerMutationsChangeLocaleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type ServerMutationsCreateRelationshipArgs = {
  input?: Maybe<CreateRelationshipInput>;
};


export type ServerMutationsUpdateRelationshipArgs = {
  input?: Maybe<UpdateRelationshipInput>;
};


export type ServerMutationsDeleteRelationshipArgs = {
  id?: Maybe<Scalars['String']>;
};


export type ServerMutationsUpdateMirrorArgs = {
  input?: Maybe<UpdateMirrorInput>;
};


export type ServerMutationsAddMirrorArgs = {
  input?: Maybe<UpdateMirrorInput>;
};


export type ServerMutationsDeleteMirrorArgs = {
  input?: Maybe<Scalars['String']>;
};


export type ServerMutationsUpdateStatusArgs = {
  status: Scalars['String'];
};


export type ServerMutationsMailResetPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type ServerMutationsChangePasswordArgs = {
  password?: Maybe<Scalars['String']>;
  resettoken?: Maybe<Scalars['String']>;
};

export type ServerQueries = {
  __typename?: 'ServerQueries';
  login?: Maybe<AuthData>;
  user?: Maybe<User>;
  validateToken?: Maybe<User>;
  listRelationships?: Maybe<RelationshipList>;
  getRelationship?: Maybe<Relationship>;
  getMirror?: Maybe<Mirror>;
};


export type ServerQueriesLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


export type ServerQueriesListRelationshipsArgs = {
  id?: Maybe<Scalars['String']>;
};


export type ServerQueriesGetRelationshipArgs = {
  id?: Maybe<Scalars['String']>;
};


export type ServerQueriesGetMirrorArgs = {
  id?: Maybe<Scalars['String']>;
};

export type Survey = {
  __typename?: 'Survey';
  surveyFeeling?: Maybe<Array<Maybe<Card>>>;
  surveyHappened?: Maybe<Array<Maybe<Card>>>;
  surveyNeeds?: Maybe<Array<Maybe<Card>>>;
};

export type UpdateRelationshipInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  animalId: Scalars['String'];
  gender?: Maybe<Gender>;
  partnerAnimalId: Scalars['String'];
  partnerGender?: Maybe<Gender>;
  relation?: Maybe<Relation>;
  partnerName: Scalars['String'];
  partnerRelationshipSurvey?: Maybe<CreateSurvey>;
  partnerTriggerSurvey?: Maybe<CreateSurvey>;
  relationshipSurvey?: Maybe<CreateSurvey>;
  triggerSurvey?: Maybe<CreateSurvey>;
};

export type ExitPoll = {
  __typename?: 'ExitPoll',
  subscriptionLikelihood: Maybe<Scalars['Int']>,
  reasons: Maybe<Array<Maybe<Scalars['String']>>>,
  explanation: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  registered?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Locale>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  playlists?: Maybe<Array<Maybe<Playlist>>>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
  userProgress?: Maybe<Scalars['JSON']>;
  userFeedback?: Maybe<Scalars['JSON']>;
  userLog?: Maybe<Scalars['JSON']>;
  playtime?: Maybe<Scalars['JSON']>;
  exitPoll?: Maybe<ExitPoll>;
  type?: Maybe<UserType>;
  isGuest?: Maybe<Scalars['Boolean']>;
  is1stFeedbackComplete?: Maybe<Scalars['Boolean']>
  isOnboardingComplete?: Maybe<Scalars['Boolean']>
};

export enum UserType {
  Parent = 'parent',
  Child = 'child',
  ParentAndChild = 'parentAndChild',
  Other = 'other'
}

export type UserInputData = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Video = {
  __typename?: 'Video';
  url?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type RelationshipList = {
  __typename?: 'relationshipList';
  relationships?: Maybe<Array<Maybe<Relationship>>>;
};

export type SurveyFeeling = {
  cardType?: Maybe<CardType>;
  isReversed?: Maybe<Scalars['Boolean']>;
  isReduced?: Maybe<Scalars['Boolean']>;
  prefix?: Maybe<Scalars['String']>;
  modifier?: Maybe<CardModifier>;
  emoji: Scalars['String'];
  isPositive: Scalars['Boolean'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  switchSides?: Maybe<Scalars['Boolean']>;
};

export type UpdateMirrorInput = {
  id: Scalars['String'];
  relationshipId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  journeyProps: Scalars['JSON'];
  journeyStepIds: Maybe<Array<String>>;
  progress?: Maybe<ProgressInputData>;
};
