import { useEffect } from "react";
import "./PayPalSubscriptionButtons.scss"

export default function PayPalAnnuallyButtons() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.paypal.com/sdk/js?client-id=AXMXyigBsNDHBdwurI7GIv7qH6L7x30t_qXj1WmNlhav8-ogBDRB78GkSlKQi509oA9QBZlpmDwk2I-7&vault=true&intent=subscription";
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.addEventListener("load", () => {
      if (window.paypal) {
        window.paypal.Buttons({
          style: {
            shape: "pill",
            color: "gold",
            layout: "vertical",
            label: "paypal",
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: "P-2DT04017CX334334CM7SG2XQ",
            });
          },
          onApprove: function (data, actions) {
            alert(data.subscriptionID);
          },
        }).render("#paypal-button-container-P-2DT04017CX334334CM7SG2XQ");
      }
    });
    document.body.appendChild(script);
  }, []);

  return <div className="PayPalSubscriptionButtons" id="paypal-button-container-P-2DT04017CX334334CM7SG2XQ"></div>;
}