import gql from 'graphql-tag';
import React, {useState, useEffect, useContext} from "react";
import { io } from "socket.io-client";
import "./PWall.scss";
import { useTranslation } from "react-i18next";
import Xbutton from "components/Xbutton";
import { useHistory } from 'react-router-dom';
import PlanToggleWithPayPalButtons from "./PlanToggleWithPayPalButtons";
import PWall_Main from "./PWall_Main";
import PWall_inProgress from "./PWall_inProgress";
import PWall_Success from "./PWall_Success";

import UserContext from "../../pages/Login/UserContext";
import {updateUserIsSubscribed} from '../../graphql/mutations';
import {updateUserLog} from '../../graphql/mutations';
import {useMutation} from 'react-apollo';


function PWall({isOpen, onExit }) {
  
    const { t } = useTranslation();
    const history = useHistory();
    const socket = io("https://felt-webapp.herokuapp.com");
    const {user, handleUserUpdate} = useContext(UserContext);
    const [subscriptionID, setSubscriptionID] = useState(undefined);
    const [PWallState, setPWallState] = useState("main")
    const [PlanSelected, setPlanSelected] = useState("monthly")

    const gotoLogin = () => {
      history.push('/login');
    }

    const togglePlans = () => {
      switch (PlanSelected){
        case "monthly":
          setPlanSelected("annually")
        break
        case "annually":
          setPlanSelected("monthly")
        break
      }
    }

    useEffect(() => {
      socket.on("paypalEvent", (data) => {
        console.log("📡 PayPal event from backend:", data);
        if(data.event_type === "BILLING.SUBSCRIPTION.ACTIVATED"){
          updateUserToSubscribed()
          setPWallState("BILLING.SUBSCRIPTION.ACTIVATED")
        }
      });
      if(subscriptionID){updateUserSubscriptionID()}
    }, [subscriptionID]);

    const [updateUserLogEntry, {data: logData, loading: logLoading, error: logError}] = useMutation(gql(updateUserLog), {
      update(cache, {data: {setUserLog}}) {
        if (!setUserLog) {
          console.error('setUserLog is undefined or null');
          return;
        }
        handleUserUpdate(setUserLog);
      },
    });
    
    const logSubscriptionEvent = async () => {
      await updateUserLogEntry({
        variables: {
          entry: {
            event: 'subscribed',
            details: {
              timestamp: new Date().toISOString(),
              method: 'credit_card',
              plan: 'monthly',
              price: 19.99,
              currency: 'USD',
            },
          },
        },
      });
    };

    const [updateUserSub, {data, loading, error}] = useMutation(gql(updateUserIsSubscribed), {
        update(cache, {data: {setUserSubscription}}) {
          if (!setUserSubscription) {
            console.error('setUserSubscription is undefined or null');
            return;
          }
          handleUserUpdate(setUserSubscription);
        },
      });

    const updateUserToSubscribed = async () => {
      await updateUserSub({variables: {isSubscribed: true}})
    };

    const updateUserToUnsubscribed = async () => {
      await updateUserSub({variables: {isSubscribed: false}})
    };

    const updateUserSubscriptionID = async () => {
      await updateUserSub({variables: {SubscriptionID: subscriptionID}})
    };
    
    const printUser = () => {
      console.log("user ", user)
    }
    return (
      <>
        {/* <div className="FLOATING_CHEATS noselect">
          <div className={"label " + (user.isSubscribed ? "label-green" :"label-red")}>{user.isSubscribed ? "SUBSCRIBED" : "NOT SUBSCRIBED"}</div>
          <div className={"label "}>{subscriptionID}</div>
          <div className={"cheats-button"} onClick={printUser}>LOG USER</div>
          <div className={"cheats-button"} onClick={user.isSubscribed ? updateUserToUnsubscribed : updateUserToSubscribed}>{user.isSubscribed ? "🔴 Unsubscribe User" : "🟢 Subscribe User"}</div>
        </div> */}
        {isOpen && <div className="PWall_darkBG noselect">
          {PWallState === "main" && <PWall_Main onExit={onExit} setSubscriptionID={setSubscriptionID}/>}
          {/* {PWallState === "BILLING.SUBSCRIPTION.CREATED" && <PWall_inProgress onExit={onExit}/>} */}
          {PWallState === "BILLING.SUBSCRIPTION.ACTIVATED" && <PWall_Success onExit={onExit}/>}
          </div>
        }
      </>
    )
  }
  
  export default PWall