import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import {mailResetPassword} from "../../graphql/mutations";
import gql from "graphql-tag";
import { useHistory } from 'react-router-dom';

const RestorePassword = ({onSwitchToLogin, initialEmail = ''}) => {

  const [sendEmailMutation, {data, error, loading}] = useMutation(gql(mailResetPassword));
  const [email, setEmail] = useState(initialEmail);
  const [sentEMail, setSentEMail] = useState(false);
  const [emailError, setEmailError] = useState(null); // State for email validation error

  const {t} = useTranslation();
  const history = useHistory();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const gotoLogin = () => {
    history.push('/login');
  }

  const onLogin = async (event) => {
    event.preventDefault();

    // Reset error messages
    setEmailError(null);

    // Check if email is empty
    if (!email) {
      setEmailError(t("loginFlow.emailRequired"));
      return;
    }

    // Validate email format
    if (!validateEmail(email)) {
      setEmailError(t("loginFlow.emailNotValid"));
      return;
    }

    try {
      await sendEmailMutation({variables: {email}});
      setSentEMail(true);
    } catch (error) {
      console.log(t("loginFlow.emailSendingError"), error);
    }
  };

  return (
    <div>
      {sentEMail ? (
        <> 
          <div className="Onboarding-content Onboarding-container-no-footer hideScrollbar">
            <h1 className="Onboarding-title">{t('modal.reset-password.title')}</h1>
            <p className="Onboarding-subtitle">{t('modal.reset-password.subtitle')}</p>
            <a href="/login">
              <button className="noselect buttonCatbears buttonCatbears_CTA register-btn">{t("modal.reset-password.backToLogin")}</button>
            </a>
          </div>
        </>
      ) : (
        <>
          <h2 className="Onboarding-title">{t("loginFlow.resetPassword")}</h2>
          <p className="Onboarding-subtitle">{t("loginFlow.resetPasswordSubtitle")}</p>
          <form onSubmit={onLogin} className="Catbears_Register">
            {/* Display email validation error */}
            {emailError && <div className="loginPage-error">😝 {emailError}</div>}
            <div className={'modal__form--error-msg ' + (error ? '' : ' displayNone ')}>
              {error?.message}
            </div>
            <div>
              <label className="Onboarding-subtitle noselect ">{t("loginFlow.email")}</label>
              <input
                type="email"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>

            <button
              className={'noselect buttonCatbears buttonCatbears_CTA register-btn ' + (loading ? 'preloaderGif ' : ' ')}
              type="submit">
              {loading ? '' : t("loginFlow.sendEmail")}
            </button>
          </form>
          <div className="modal__below-submit">
            <p>
              {t("loginFlow.rememberPassword")}&nbsp;
              <a className="noselect catbearsLink "
                 onClick={gotoLogin}>
                {t("loginFlow.logInBtn")}
              </a>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default RestorePassword;
