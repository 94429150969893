import React, { useState } from "react";
import "./PlanToggleWithPayPalButtons.scss";
import { useTranslation } from "react-i18next";
import PayPalMonthlyButtons from "components/PayPal/PayPalMonthlyButtons";
import PayPalAnnuallyButtons from "components/PayPal/PayPalAnnuallyButtons";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import SecurePaymentLine from "./SecurePaymentLine";

function PlanToggleWithPayPalButtons({ defaultPlan, setSubscriptionID }) {
  const { t } = useTranslation();

  const [planSelected, setPlanSelected] = useState(defaultPlan || "monthly");

  const planMonthlyDetails = {
    pricePerMonth: 29,
    name: t("general.monthly"),
    currency: "₪",
  };

  const planAnnuallyDetails = {
    pricePerMonth: 23,
    name: t("general.annually"),
    currency: "₪",
  };

  const selectedPlan =
    planSelected === "monthly" ? planMonthlyDetails : planAnnuallyDetails;

    const togglePlans = () => {
        switch (planSelected){
          case "monthly":
            setPlanSelected("annually")
          break
          case "annually":
            setPlanSelected("monthly")
          break
        }
      }

  return (
    <>
      <div className="PWall_deal-container noselect">
        <div className="PWall_price-container">
          <div className="price_number">{selectedPlan.pricePerMonth} <div className="price_currency">{selectedPlan.currency}</div></div>
          
          <div className="price_planDuration">{t("general.perMonth")}</div>
        </div>
        <div className="PWall_deal_plans-toggle-container">
          <div className="PWall_deal_plans-toggle-container_text">{planMonthlyDetails.name}</div>
          <ToggleSwitch
            settingsToggle
            settingsToggleTwoSidesSame
            onToggle={togglePlans}
          />
          <div className="PWall_deal_plans-toggle-container_text">{planAnnuallyDetails.name}</div>
        </div>
      </div>
      {planSelected === "monthly" ? 
            <>
            <PayPalMonthlyButtons planType={planSelected} setSubscriptionID={setSubscriptionID} />
            </>
            : 
            <PayPalAnnuallyButtons planType={planSelected} setSubscriptionID={setSubscriptionID}/>
      }
      <SecurePaymentLine />
      
    </>
  );
}

export default PlanToggleWithPayPalButtons;