import React, {useState} from "react";

const Category = ({
  isArchive, 
  formatDuration, 
  handleDeleteCategory, 
  handleMoveCategoryDown, 
  handleMoveCategoryUp, 
  categoryIndex, 
  loadURL, 
  handleAddVideo, 
  handleArchiveVideo, 
  handleDeleteVideo, 
  handleEditCategoryName, 
  videosList, 
  categoryName, 
  colorClass, 
  editMode,
  }) => {


    const getVideoID = (video) => {


        if(video.includes("youtube.com")){
          var videoID = "";
          videoID = video.slice("https://www.youtube.com/watch?v=".length);
          videoID = videoID.split("&")[0];
          videoID = videoID.split("?t=")[0];
          return videoID;
        }else{
          if(video.includes("youtu.be")){
            var videoID = "";
            videoID = video.slice("https://youtu.be/".length);
            videoID = videoID.split("&")[0];
            videoID = videoID.split("?t=")[0];
            return videoID;
          }
        }
      } 

      const copyText = (text) => {
        navigator.clipboard.writeText(text).then(() => {
          console.log("Copied to clipboard:", text);
        });
      };
      

      return (
      <div className={"tofu__rightMenu--list " + colorClass + (isArchive && !editMode ? " displayNone" : " ")}>  
        <div className="tofu__rightMenu--list--title-container">
          <h2 className="tofu__rightMenu--list--title">{categoryName}</h2>      
          {!isArchive &&<div className={"controls__btn controls__btn--pencil tofu__rightMenu--list--btn " + (editMode ? "Playtime_fade-in" : "displayNone")} onClick={() => handleEditCategoryName(categoryName, categoryIndex)} ></div>}
        </div>  
        <div className="tofu__rightMenu--list--container">
        {editMode && !isArchive && <div className="tofu__list--item">
            <div className={" controls__btn controls__btn--edit" } onClick={() => handleMoveCategoryUp(categoryIndex)} >⬆</div>
            <div className={" controls__btn controls__btn--edit" } onClick={() => handleMoveCategoryDown(categoryIndex)} >⬇</div>
            <div className={" controls__btn controls__btn--edit controls__btn--trash" } onClick={() => handleDeleteCategory(categoryIndex)} >🗑</div>
        </div>}
        { <div className="tofu__list--item">
            <div className={"controls__btn controls__btn--plus tofu__list--item--container tofu__list--item--plus " } onClick={() => handleAddVideo(categoryIndex)} ></div>
        </div>}
        {videosList.map((videoItem, index) => (
            <div key={index} className="tofu__list--item">
                <div className={"tofu__list--item--container " + (videoItem.recommended ? "tofu__list--item--recommended" : "")}>
                    <img
                        onClick={() => loadURL(videoItem.url, categoryIndex)}
                        className="rounded-corners"
                        alt={index}
                        src={
                        "https://img.youtube.com/vi/" +
                        getVideoID(videoItem.url) +
                        "/mqdefault.jpg"
                        }
                    />
                    {videoItem.duration && <p className="video-title--duration">{formatDuration(videoItem.duration)}</p>}
                    {/* {videoItem.watched && <p className="video-title--watched">{formatDuration(videoItem.watched)}</p>} */}
                    {videoItem.watched && videoItem.duration && (
                      <div className="video-progress-bar--container">
                        <div className="video-progress-bar">
                          <div
                            className="video-progress-bar__fill"
                            style={{ width: `${videoItem.duration ? (videoItem.watched / videoItem.duration) * 100 : 0}%` }}
                            ></div>
                        </div>
                      </div>
                    )}
                    <p onClick={() => loadURL(videoItem.url)} className="video-title">{videoItem.name}</p>
                {editMode && 
                    <div className="video-url">
                        <div className="video-url_button" onClick={()=>copyText(videoItem.url)}>copy</div>
                        <p className="video-title">{videoItem.url}</p>
                    </div>}
                </div>
                {editMode && !isArchive && <div className={"tofu__list--item--archiveBtn " + (isArchive && "tofu__list--item--deleteBtn ")} onClick={() => handleArchiveVideo(categoryIndex, index)}></div>}
                {editMode && isArchive && <div className={"tofu__list--item--archiveBtn " + (isArchive && "tofu__list--item--deleteBtn ")} onClick={() => handleDeleteVideo(index)}></div>}
            </div>
            ))}
          
        </div>
      </div>
    )
}

export default Category;
