import React from "react";
import "./SecurePaymentLine.scss";
import { useTranslation } from "react-i18next";

function SecurePaymentLine({}) {
  
    const { t } = useTranslation();
    
    return (
      <>
        <div className="secure-payment-line"> 
            <img src="assets/icons/icon_lock.svg" alt="lock icon" />
            <div>{t("general.securePayment")}</div>
        </div>
      </>
    )
  }
  
  export default SecurePaymentLine;