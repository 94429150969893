import React from 'react';
import { useTranslation } from 'react-i18next';
import LessonStepItem from "./LessonStepItem";
import KeyboardNavigateLesson from './KeyboardNavigateLesson'; 
import SideBarPanelNav from "components/SideBarPanelNav/SideBarPanelNav";
import "./LessonPanel.scss"
const LessonPanel = ({
    currentLessonRecipe,
    stepsRecipe,
    StepProgIndex,
    setStepProgIndex,
    CurrentSelectedStep,
    CurrentLessonIndex,
    LessonProgIndex,
    handleOpenLobby,
    setCurrentSelectedStep,
    setCurrentSelectedStepItem,
    FadeOutContent,
    MaxSteps,
    handleOpenStepper,
    stepName,
    stepDescription,
    stepRequirements,
    StepRequirement,
    AnimalType,
    lessonStepsProgress
    
    }) => {
    const {t} = useTranslation();
  
    
    const calcStepState = (stepIndex) => {
        if(LessonProgIndex > CurrentLessonIndex){
            return 'completed'
        } else if (StepProgIndex === stepIndex) {
            return 'enabled';
        } else if (StepProgIndex > stepIndex) {
        // } else if (lessonStepsProgress[stepIndex].completed) {
            return 'completed';
        } else {
            return 'disabled';
        }
    };

    KeyboardNavigateLesson(CurrentSelectedStep, setCurrentSelectedStep,( (LessonProgIndex > CurrentLessonIndex) ? MaxSteps-1 : StepProgIndex), handleOpenStepper)
    
    return (
      <>
        <div className={"Lesson  " }>

            <div className={"Lesson--side-a noselect " + (FadeOutContent ? "fadeOutSlow" : "")}>
                <SideBarPanelNav
                    left1={'backButton'}
                    leftTo={'/theatre'}
                    right={'lobby'}
                    rightAction={handleOpenLobby}
                    center={'twoLinesTopSubTitle'}
                    centerTitle={t(currentLessonRecipe.name)}
                />
                <div className="lesson-steps--container hideScrollbar texutre ">
                    {stepsRecipe.map((step, index) => {
                        return (
                        <LessonStepItem
                            key={index}
                            index={index}
                            buttonShape={step.buttonShape ? step.buttonShape : "rectangle"}
                            name={t(step.name)}
                            size={step.size}
                            type = {step.type}
                            stepState={calcStepState(index)}
                            StepProgIndex={StepProgIndex}
                            setStepProgIndex={setStepProgIndex}
                            CurrentSelectedStep={CurrentSelectedStep}
                            setCurrentSelectedStep={setCurrentSelectedStep}
                            setCurrentSelectedStepItem={setCurrentSelectedStepItem}
                            AnimalName={step.AnimalName}
                            coinName={step.coinName}
                            StepNumberToShow={step.StepNumberToShow ? step.StepNumberToShow : null}
                            lessonCompleted = {LessonProgIndex > CurrentLessonIndex}
                            ImageToShow = {step.ImageToShow ? step.ImageToShow : null}
                        />
                        );
                    })}
                </div>
                <div className="LessonDetails-MOBILE" onClick={handleOpenStepper}>
                    <div className="LessonDetails-MOBILE__top">
                        <div onClick={handleOpenStepper} className="step-details--text">
                            {/* <div className="step-details--text__side-b">
                                <div className=" cursorPointer">
                                    <button  className={"buttonCatbears panelCTA step-details--btn" + (calcStepState(CurrentSelectedStep) == "completed" ? " buttonCatbears_White " : "   buttonCatbears_CTA")}>
                                        <img src="/assets/icons/icon_play_outline_black.svg" alt="play button" />
                                    </button>
                                </div>
                            </div> */}
                            <div className="step-details--text__side-a">
                                <div className="step-details--name">{stepsRecipe[CurrentSelectedStep].StepNumberToShow && stepsRecipe[CurrentSelectedStep].StepNumberToShow + "."} {stepName}</div>
                                <div className="step-details--description">{stepDescription}</div>
                            </div>
                        </div>
                        <div className="lesson-video-thumbnail" >
                            <div className="lesson-video-thumbnail">
                                <img src={stepsRecipe[CurrentSelectedStep].videoThumbnail} alt="video thumbnail" />
                            </div>
                        </div>
                    </div>
                    {calcStepState(CurrentSelectedStep) != "completed" && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>}
                            <button  className={"buttonCatbears panelCTA " + (calcStepState(CurrentSelectedStep) == "completed" ? "buttonCatbears_White " : " buttonCatbears_CTA breathCurrentOutline")}>{calcStepState(CurrentSelectedStep) == "completed" ? t("general.replay") : t("general.startStep")}</button>
                </div>
                
            </div>
            <div className={"Lesson--side-b noselect " + (FadeOutContent ? "fadeOutSlow" : "")}>
                <div className="lesson-video--container " onClick={handleOpenStepper}>
                    
                    <div className="lesson-video-thumbnail">
                        <img src={stepsRecipe[CurrentSelectedStep].videoThumbnail} alt="video thumbnail" />
                    </div>
                </div>
                <div className="step-details ">
                    <div className="step-details--a noselect ">
                        <div onClick={handleOpenStepper} className="instructor-cta-container cursorPointer">
                        {/* <button  className={"buttonCatbears panelCTA step-details--btn" + (calcStepState(CurrentSelectedStep) == "completed" ? " buttonCatbears_White " : "   buttonCatbears_CTA")}>
                                </button> */}
                            
                            {calcStepState(CurrentSelectedStep) != "completed" && <div className="ArrowSelected"><img src="/assets/icons/icon_arrowSelected.svg" alt="icon white arrow" /></div>}
                            <button  className={"buttonCatbears panelCTA " + (calcStepState(CurrentSelectedStep) == "completed" ? "buttonCatbears_White " : " buttonCatbears_CTA breathCurrentOutline")}>{calcStepState(CurrentSelectedStep) == "completed" ? t("general.replay") : t("general.startStep")}</button>
                            {/* <img src="/assets/icons/icon_play_outline_black.svg" alt="play button" /> */}
                        </div>
                    <div className="step-details--name">{stepName}</div>
                    <div className="step-details--description">{stepDescription}</div>
                        
                    </div>
                    <div className="step-details--b noselect ">
                        <div className="step-details--container">
                        {(stepRequirements || !stepRequirements == null) ?
                        <>
                            <div className="stepRequirements--title">{t("general.prepForStep")}</div>
                            <div className="stepRequirements--container">
                            {stepRequirements.map((Requirement, index) => {
                                return (
                                    <StepRequirement
                                        key={index}
                                        type={Requirement.name}
                                    />
                                )
                            })}
                            </div>
                        </>
                        : ""
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>  
      </>)
  }

export default LessonPanel;
