// Updated OverlayMainVideo component with hooks, preserving all original structure and classes
import React, {useState} from "react";
import { 
  handlePlayPause,
  handleMouseMoveOverVideoTimout,
  handlePlayTime,
  handleClickFullscreen,
  handleReplay,
  handleJumpBackwards,
  handleJumpForward
  } from '../PlaytimeUtils/playtimeFunctions';

function OverlayMainVideo({
  playing,
  setPlaying,
  url,
  playerRef,
  rightMenu,
  handleRightMenu,
  countDownCloseToFinish,
  setCountDownCloseToFinish,
  setPlayTimeCountdownON,
  playTimeCountdownON,
  countDownLastMinute,
  setCountDownLastMinute,
  playTimeOver,
  setPlayTimeOver,
  videoDuration,
  userEmail,
  videoWatched
}) {
  const [mouseMovingOverVideo, setMouseMovingOverVideo] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [playTimeCountdown, setPlayTimeCountdown] = useState(false);
  const [progress, setProgress] = useState(0);



  return (
    <div>
      <div
        id="overlay"
        className={" " + (playing ? "showOnHover " : "")}
        onMouseMove={()=>handleMouseMoveOverVideoTimout(playing,mouseMovingOverVideo,setMouseMovingOverVideo)}
      >
        <div
          className={"overlay__bg " + (playing ? "" : "overlay__bg--pause")}
          onClick={()=>handlePlayPause(playing, setPlaying)}
        ></div>
        <div className="overlay__header">
          <div
            className={"logo__container " + (countDownCloseToFinish ? "wiggle" : "")}
            onClick={()=>handlePlayTime(
              setPlayTimeCountdownON,
              setPlayTimeCountdown,
              setCountDownCloseToFinish,
              setCountDownLastMinute,
              setPlayTimeOver,
              countDownLastMinute,
              countDownCloseToFinish,
              playTimeOver,

            )}
          >
            <div className="logo__symbol">
              <div
                className={
                  "logo__symbol--play-icon " +
                  (playTimeCountdownON ? "displayNone" : "")
                }
              ></div>
              <p
                id="logo__symbol--countdown"
                className={playTimeCountdownON ? "" : "displayNone"}
              >
                {playTimeCountdown}
              </p>
            </div>
            <h1>PlayTime</h1>
          </div>
          <div className="controls__settings">
            <a href="/login" className="controls__btn controls__btn--edit">{userEmail ? userEmail : "login/create"}</a>
            <div
              className="controls__btn controls__btn--fullscreen controls__btn--no-bg controls__btn--no-padding"
              onClick={()=>handleClickFullscreen(fullscreen, setFullscreen)}
              ></div>
          </div>
        </div>
        <div
          id="overlay__main-icon"
          className="iconVideoBabu iconVideoBabu__L Playtime_fade-in-out shape-nonagon"
          onClick={()=>handlePlayPause(playing, setPlaying)}
        >
          <div className={"iconVideoBabu__icon " + (playing ? "shape-pause" : "shape-play")}></div>
        </div>
        <div className="overlay__bottom">
          <div
            className="controls__btn controls__btn--medium controls__btn--replay"
            onClick={()=>handleReplay(playerRef, url, setPlaying)}
          ></div>
          {videoDuration !== "NaN:NaN" && <div className={"controls__btn controls__btn--medium video-duration"} >{videoWatched + " / " + videoDuration}</div>}

          <div className="overlay__bottom--center-container">
            <div
              className="controls__btn controls__btn--jump-backwards controls__btn--tiny shape-circle"
              onClick={()=>handleJumpBackwards(playerRef, progress, setProgress)}
            ></div>
            <div
              className={
                "controls__btn nonagon-shape " +
                (playing ? "controls__btn--pause" : "controls__btn--play")
              }
              onClick={()=>handlePlayPause(playing, setPlaying)}
            ></div>
            <div
              className="controls__btn controls__btn--jump-forward controls__btn--tiny shape-circle"
              onClick={()=>handleJumpForward(playerRef, progress, setProgress)}
            ></div>
          </div>
          <div
            className={
              "controls__btn controls__btn--arrow shape-circle " +
              (rightMenu ? "" : "flip-vertical")
            }
            onClick={handleRightMenu}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default OverlayMainVideo;




// import React from "react";

// OverlayMainVideo = () => {
//     return (
//       <div>
//         <div id="overlay" className={" " + (this.state.playing ? "showOnHover " : "")} onMouseMove={this.handleMouseMoveOverVideoTimout}>
//           <div className={"overlay__bg " + (this.state.playing ? "" : "overlay__bg--pause")} onClick={this.handlePlayPause}></div>
//           <div className="overlay__header">
//             <div className={"logo__container " + (this.state.countDownCloseToFinish ? "wiggle" : "") } onClick={this.handle§}>
//               <div className="logo__symbol">
//                 <div className={"logo__symbol--play-icon " + (this.state.playTimeCountdownON ? "displayNone" : "" )}></div>
//                 <p id="logo__symbol--countdown" className={(this.state.playTimeCountdownON ? "" : "displayNone" )}>{this.state.playTimeCountdown}</p>
//               </div>
//               <h1>PlayTime</h1>
//             </div>
//             <div className="controls__btn controls__btn--fullscreen controls__btn--no-bg controls__btn--no-padding " onClick={this.handleClickFullscreen}></div>
//             {/* <div className={"controls__btn controls__btn--edit " + (this.state.editMode ? " " : "controls__btn--edit--off")} onClick={this.handleEditMode} ></div> */}
//           </div>
//           <div id="overlay__main-icon" className="iconVideoBabu iconVideoBabu__L fade-in-out shape-nonagon" onClick={this.handlePlayPause}>
//             <div className={"iconVideoBabu__icon " + (this.state.playing ? "shape-play" : "shape-pause")}></div>
//           </div>
//           <div className="overlay__bottom">
//             <div className="controls__btn controls__btn--medium controls__btn--replay " onClick={this.handleReplay}></div>
//             <div className="overlay__bottom--center-container">
//               <div className="controls__btn controls__btn--jump-backwards controls__btn--tiny shape-circle" onClick={this.handleJumpBackwards}></div>
//               <div className={"controls__btn nonagon-shape " + (this.state.playing ? "controls__btn--pause" : "controls__btn--play")} onClick={this.handlePlayPause}></div>
//               <div className="controls__btn controls__btn--jump-forward controls__btn--tiny shape-circle" onClick={this.handleJumpForward}></div>
//             </div>
//             {/* <div className={" " + (this.state.rightMenu ? "" : "controls__btn controls__btn--right-arrow flip-horizontal")} onClick={this.handleRightMenu}></div> */}
//             {/* <div className={"controls__btn controls__btn--menu " + (this.state.rightMenu ? "hide" : " flip-horizontal")} onClick={this.handleRightMenu}></div> */}
//             <div className={"controls__btn controls__btn--arrow shape-circle " + (this.state.rightMenu ? "" : "flip-vertical")} onClick={this.handleRightMenu}></div>
//           </div>
//         </div>
//       </div>
//     )
//   }

//   export default OverlayMainVideo;