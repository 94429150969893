import React from 'react';
import './Xbutton.scss';

const Xbutton = ({ fixed, onClose, sizeM }) => {
  return (
    <div
      onClick={onClose}
      className={
        "top-navbar--circle noselect Xbutton " +
        (sizeM ? "sizeM " : "") +
        (fixed ? "fixed" : "")
      }
    >
      <img src="/assets/icons/iconX.svg" alt="Close" />
    </div>
  );
};

export default Xbutton;
